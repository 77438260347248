/* Faq.css */

.faq-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Startet die Inhalte am Anfang des Containers */
  align-items: center; /* Zentriert die Inhalte horizontal */
  padding: 20px 20px; /* Fügt Polster oben und an den Seiten hinzu */
  min-height: calc(100vh - 120px); /* Setzt eine minimale Höhe unter Berücksichtigung der Kopf- und Fußzeile */
  color: white;
  background-color: #121212; /* Dunkler Hintergrund */
  font-family: Arial, sans-serif;
}

.faq-item {
  width: 100%;
  max-width: 800px; /* Maximalbreite für die FAQ-Elemente */
  margin: 10px 0; /* Fügt Abstand oben und unten hinzu */
  padding: 20px;
  background: #222; /* Hintergrundfarbe für FAQ-Elemente */
  border-radius: 4px; /* Abgerundete Ecken */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Schatten für die Elemente */
  cursor: pointer;
  transition: background 0.3s; /* Sanfter Übergang für die Hintergrundfarbe */
}

.faq-item:hover {
  background: #333; /* Hintergrundfarbe beim Hover */
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.faq-answer {
  padding-top: 10px;
  display: none; /* Standardmäßig nicht angezeigt */
}

.faq-item.open .faq-answer {
  display: block; /* Wird angezeigt, wenn das Element offen ist */
}

.faq-toggle {
  font-size: 1.5em;
  margin-left: 10px;
}
