.text-container {
    font-size: 1.5em;
    color: #21ba45;
    margin:auto
}

.caret {
    display: inline-block;
    animation: blink 1s infinite;
    vertical-align: 3px;
}

@keyframes blink {
    0%, 49% { opacity: 1; }
    50%, 100% { opacity: 0; }
}
