.Home {
  color: white;
  text-align: center;
  padding: 50px 20px;
  background-color: #121212; /* Dunkler Hintergrund */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.title {
  font-size: 4rem; /* Größe angepasst */
  margin: 0;
  color: #21ba45; /* Grüne Schrift */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Textschatten für besseren Kontrast */
}

.tagline {
  font-size: 1.5rem;
  margin: 20px 0;
}

.description {
  margin: 20px 0;
  max-width: 600px; /* Maximale Breite für den Beschreibungstext */
}

.play-button {
  background-color: #21ba45; /* Grüne Hintergrundfarbe */
  color: #fff;
  border: 2px solid #21ba45; /* Grüne Randfarbe */
  padding: 10px 30px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 20px; /* Abgerundete Ecken */
  margin-top: 20px; /* Abstand von der Beschreibung */
  margin-bottom: 20px; /* Abstand von der Beschreibung */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Schatten für den Button */
  transition: background-color 0.3s, box-shadow 0.3s;
}

.play-button:hover {
  background-color: #16ab39; /* Dunkleres Grün für Hover-Effekt */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Stärkerer Schatten für den Hover-Effekt */
}

.footer-text {
  position: absolute;
  bottom: 20px; /* Unten im Container positioniert */
  text-align: center;
  font-size: 0.875rem;
}

.footer-text p {
  margin: 5px 0;
}

.research-link {
  color: #21ba45; /* Grüne Schrift für den Link */
  text-decoration: none; /* Keine Unterstreichung */
}

.research-link:hover {
  text-decoration: underline; /* Unterstreichung beim Hover */
}
