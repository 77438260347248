html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: #222;
  color: white;
  padding: 20px;
  text-align: center;
  flex-shrink: 0; /* Verhindert, dass der Header schrumpft */
}

.Home {
  flex: 1; /* Lässt den Inhalt den verfügbaren Platz einnehmen */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.App-footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.App-footer a {
  color: white;
  padding: 0 10px;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}
