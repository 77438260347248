/* PrivacyPolicy.css */

.privacy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px; /* Passt den Abstand vom oberen Rand an */
  padding-bottom: 50px; /* Abstand vom unteren Rand */
  color: white;
  background-color: #121212; /* Dunkler Hintergrund */
  min-height: calc(100vh - 50px); /* Mindesthöhe abzüglich Fußzeile */
  font-family: Arial, sans-serif;
}

.privacy-heading {
  margin-bottom: 20px; /* Abstand unter der Überschrift */
}

.privacy-text {
  width: 80%; /* Breite des Textcontainers */
  max-width: 800px; /* Maximalbreite */
  margin-bottom: 20px; /* Abstand zwischen den Absätzen */
}
